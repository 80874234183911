import { useEffect, useState } from "react";
import { isWindowDefined } from "@inferno/renderer-shared-core";

export enum ScrollDirection {
  UP = "up",
  DOWN = "down",
}

export const useScrollDirection = (threshold: number, containerElementName = "body") => {
  const [scrollDir, setScrollDir] = useState(ScrollDirection.UP);

  useEffect(() => {
    if (isWindowDefined()) {
      const container = document.querySelector(`${containerElementName}`);
      let previousScrollYPosition = container!.scrollTop;

      const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
        Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

      const isScrollingUp = (currentScrollYPosition: number) =>
        currentScrollYPosition > previousScrollYPosition &&
        !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
        !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

      const updateScrollDirection = (target: HTMLElement) => {
        const currentScrollYPosition = target.scrollTop;

        if (scrolledMoreThanThreshold(currentScrollYPosition)) {
          const newScrollDirection = isScrollingUp(currentScrollYPosition) ? ScrollDirection.DOWN : ScrollDirection.UP;
          setScrollDir(newScrollDirection);
          previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
        }
      };

      const onScroll = (event: Event) => {
        const target = event.target as HTMLElement;
        if (containerElementName !== "body" && target && !target.matches(`${containerElementName}`)) {
          return null;
        } else {
          window.requestAnimationFrame(() => updateScrollDirection(target));
        }
      };

      window.addEventListener("scroll", onScroll, true);

      return () => window.removeEventListener("scroll", onScroll);
    }
  });

  return scrollDir;
};
