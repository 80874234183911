import { inject } from "mobx-react";
import { useEffect, useState } from "react";

import { LogoImg } from "../../../core/chrome/StationLogo.component";
import "../../../core/chrome/StationLogo.style.scss";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { isWindowDefined, resizeWatcher } from "@inferno/renderer-shared-core";
import { RESPONSIVE_IMAGE, COMPONENT_BREAKPOINTS } from "../../../core/ui/constants";
import type { Store } from "@inferno/renderer-shared-core";

interface PremiereStationLogoProps {
  store?: Store;
  location: string;
}

export const PremiereLogo = inject("store")(({ store, location }: PremiereStationLogoProps) => {
  if (!store) {
    return null;
  }

  const { site, env } = store;

  if (!site.sections.design?.station_logo?.id || !site.sections.general) {
    return null;
  }
  const [isDesktop, setIsDesktop] = useState(false);

  const handleWindowResize = () => {
    if (window.innerWidth > COMPONENT_BREAKPOINTS.mobileBreakpoint) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  };

  useEffect(() => {
    if (isWindowDefined()) {
      handleWindowResize();
      resizeWatcher.onWidthChange.subscribe(handleWindowResize);
      return () => resizeWatcher.onWidthChange.unsubscribe(handleWindowResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let width = RESPONSIVE_IMAGE.premiereLogoMobileWidth;
  let height = RESPONSIVE_IMAGE.premiereLogoMobileHeight;

  if (isDesktop) {
    width = RESPONSIVE_IMAGE.premiereLogoDesktopWidth;
    height = RESPONSIVE_IMAGE.premiereLogoDesktopHeight;
  }

  if (location === "header-block") {
    let HostImage = false;
    width = RESPONSIVE_IMAGE.premiereLogoDesktopWidth;
    height = RESPONSIVE_IMAGE.premiereLogoDesktopHeight;

    if (site.sections.design?.thumbnail_image?.id) {
      HostImage = true;
    }

    if (HostImage && isDesktop) {
      width = RESPONSIVE_IMAGE.premiereLogoWithHostDesktopWidth;
      height = RESPONSIVE_IMAGE.premiereLogoWithHostDesktopHeight;
    } else if (HostImage && !isDesktop) {
      width = RESPONSIVE_IMAGE.premiereLogoWithHostMobileWidth;
      height = RESPONSIVE_IMAGE.premiereLogoWithHostMobileHeight;
    }
  }

  return (
    <div className="station-logo">
      {site.sections.design?.station_logo?.id && (
        <LogoImg
          imgUrl={formatImage(site.sections.design?.station_logo?.id, env.IMAGE_HOST)}
          width={width}
          height={height}
          stationUrl="/"
          alt={`${site.sections.general?.name} - ${site.sections.general?.positioner}`}
        />
      )}
    </div>
  );
});
