import { slugify } from "@ihr-radioedit/inferno-core";
import { inject } from "mobx-react";
import { useMemo } from "react";
import { Translation } from "react-i18next";
import { SitesMenu } from "../../../core/chrome/Navigation.component";
import { MenuProps } from "../../../core/chrome/NavigationMenu.component";
import { DropDownIcon } from "../../../core/components/icons/DropDownIcon.component";
import { MagicLink } from "../../../core/ui";
import { Remote } from "../../../core/components/remote/Remote.component";
import { resolveMenu } from "../../../core/lib/menu";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { getContests } from "@ihr-radioedit/inferno-core";
import styles from "./PremiereNav.module.scss";

export const PremiereContestsNavigationMenu = inject("store")(
  ({
    store,
    callback,
    forceOpen = false,
    top = 0,
  }: Omit<MenuProps, "menu"> & { top?: number; callback?: (arg: string) => void }) => {
    if (!store) {
      return null;
    }

    const brandIds = useMemo(() => {
      const result: { [key: string]: string } = {};
      store.site.micrositeReferences.forEach(index => (result[`/index${index._id}`] = index.slug));
      return result;
    }, [store.site.micrositeReferences]);

    const { navigation } = store.site.sections ?? {};

    const { slug: accountId } = store.site.index;
    const cacheKey = `contestsloader-${accountId}`;
    const contestRules = (
      <Translation>
        {t => (
          <MagicLink
            type={"link"}
            className={styles.menuLabel}
            to={reverseRoute(store.site, "rules") ?? ""}
            label={t("contest_rules")}
          >
            {t("contest_rules")}
          </MagicLink>
        )}
      </Translation>
    );

    return (
      <li className={styles.topLabel}>
        <Translation>
          {t => (
            <button className={styles.menuLabel} onClick={() => callback?.(t("promotions"))}>
              {t("promotions")}
              <span className={styles.icon}>
                {forceOpen ? <DropDownIcon direction="up" /> : <DropDownIcon direction="down" />}
              </span>
            </button>
          )}
        </Translation>
        <div className={styles.dropdownOverlay}>
          <ul
            className={`${styles.submenu} ${forceOpen ? "visible" : ""}`}
            aria-hidden={!!forceOpen}
            style={{
              maxHeight: `calc(100vh - ${top + 100}px)`,
            }}
          >
            <Remote
              loader={() => getContests({ accountId }, store.tags.surrogateKeys, store.getSdkOpts())}
              cacheKey={cacheKey}
              fallback={contestRules}
              showLoading={false}
            >
              {({ data }) => {
                if (data?.length) {
                  const items = data.slice(0, 5).map((contest, i) => {
                    const menuItem: SitesMenu = {
                      type: "link",
                      label: contest.title.trim(),
                      href: store?.site
                        ? contest.gridRedirect ||
                          reverseRoute(store?.site, "promotion", {
                            slug: `${slugify(contest.title.trim(), 6)}-${contest.appId}`,
                          })
                        : "#",
                      context: `contest_${i}`,
                    };
                    return (
                      <li key={`contest_${i}`}>
                        <Translation>
                          {t => (
                            <MagicLink
                              className={styles.menuLabel}
                              to={menuItem.href || ""}
                              label={menuItem.label || ""}
                              target={menuItem.target || ""}
                              context={menuItem.context}
                            >
                              {t(`${menuItem.label}`)}
                            </MagicLink>
                          )}
                        </Translation>
                      </li>
                    );
                  });

                  return (
                    <>
                      {items}
                      <li>
                        <Translation>
                          {t => (
                            <MagicLink
                              type={"link"}
                              className={styles.menuLabel}
                              to={reverseRoute(store.site, "promotions") ?? ""}
                              label={t("all_promotions")}
                            >
                              {t("all_promotions")}
                            </MagicLink>
                          )}
                        </Translation>
                      </li>
                    </>
                  );
                }
                return null;
              }}
            </Remote>
            {navigation?.national_promotions
              ? navigation.national_promotions.map((item, i) => {
                  const itemMenu = resolveMenu(item, store.env.MICROSITE_ROOT, null, store.site, brandIds);
                  return (
                    <li key={`np_${i}`}>
                      <Translation>
                        {t => (
                          <MagicLink
                            type={"link"}
                            className={styles.menuLabel}
                            to={itemMenu.href ?? ""}
                            label={itemMenu.label ?? ""}
                            context={itemMenu.label ? `${slugify(itemMenu.label)}_${i}` : undefined}
                          >
                            {t(`${itemMenu.label}`)}
                          </MagicLink>
                        )}
                      </Translation>
                    </li>
                  );
                })
              : null}
            <li>{contestRules}</li>
          </ul>
        </div>
      </li>
    );
  },
);
