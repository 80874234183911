import { inject, observer } from "mobx-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { SocialIcons, SiteFile, resolveLocalFooter, CopyRight } from "@inferno/renderer-shared-ui";
import { ClickSection } from "@inferno/renderer-shared-core";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { getLocale } from "@ihr-radioedit/inferno-core";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { socialConfigAbstract } from "@inferno/renderer-shared-core";
import { MagicLink } from "./../../../core/ui";
import "./Footer.style.scss";
import { LogoImg } from "../../../core/chrome/StationLogo.component";
import type { Store } from "@inferno/renderer-shared-core";

interface FooterProps {
  store?: Store;
  amp?: boolean;
}

export const Footer = inject("store")(
  observer(({ store, amp }: FooterProps) => {
    if (!store) {
      return null;
    }
    const { site, env, page } = store;
    const { sections, integration } = site;
    const { t } = useTranslation();
    const ownership = site.index?.ownership?.id || "";
    const iHeartApp = ownership !== "aloha trust" && sections.navigation?.enable_podcasts_links_switch;
    const newNetwork = socialConfigAbstract(sections.social);
    const resolvedLocalMenu = resolveLocalFooter(site, sections.navigation?.menu_footer);
    const localMenu = resolvedLocalMenu
      ? resolvedLocalMenu.map((menuItem, index: number) => (
          <li key={index}>
            <MagicLink key={index} to={menuItem.href || menuItem.ref || ""} target={menuItem.target || ""}>
              {menuItem.label}
            </MagicLink>
          </li>
        ))
      : null;
    const [locale] = getLocale(site).split("-");
    const assistanceFile = sections.contact?.public_file_assistance_link_override
      ? sections.contact?.public_file_assistance_link_override
      : `/assistance/${locale}/`;

    const ownershipTag = ownership?.includes("providers/") || false;
    const ownershipTranslation = iHeartApp ? "footer-iheart" : "footer-aloha";
    const ownershipFallback = ownership ? ownership.replace("providers/", "") : "";
    const footerClass = classNames("component-Site-Footer", {
      "page-loading": !page.currentPage,
    });

    return (
      <ClickSection.Provider value="footer">
        <footer className={footerClass}>
          <section>
            <div className="top-footer">
              <div className="footer-row footer-links">
                {newNetwork ? (
                  <div className="social-network-icons">
                    <SocialIcons networks={newNetwork} />
                  </div>
                ) : null}
                {sections.general?.description ? (
                  <div className="description">{sections.general.description}</div>
                ) : null}
                {sections.navigation?.menu_footer?.length ? (
                  <ul className="footer-station-links">{localMenu}</ul>
                ) : null}

                <ul className="footer-national-links">
                  {sections.navigation?.contact_menu_switch ? (
                    <li>
                      <MagicLink to={reverseRoute(site, "contact") || ""}>{t("footer-contact")}</MagicLink>
                    </li>
                  ) : null}
                  {sections.navigation?.advertise_switch ? (
                    <li>
                      <MagicLink to={reverseRoute(site, "advertise") || ""}>
                        {t("footer-advertise", { station: `${sections.general?.name}` })}
                      </MagicLink>
                    </li>
                  ) : null}
                  {iHeartApp ? (
                    <Fragment>
                      <li>
                        <MagicLink to={`${env.NATIONAL_SITE_URL}/apps/`} target="_blank">
                          {t("footer-app")}
                        </MagicLink>
                      </li>
                      <li>
                        <MagicLink to={`${env.NATIONAL_SITE_URL}/podcast/`} target="_blank">
                          {t("footer-podcast")}
                        </MagicLink>
                      </li>
                    </Fragment>
                  ) : null}
                </ul>
              </div>
            </div>
            <div className="lower-footer">
              <div className="footer-logo">
                {site.sections.design?.logo_footer?.id && (
                  <LogoImg
                    imgUrl={formatImage(site.sections.design?.logo_footer?.id, env.IMAGE_HOST)}
                    width={128}
                    height={44}
                    stationUrl="/"
                    alt={`${site.sections.general?.name} - ${site.sections.general?.positioner}`}
                  />
                )}
              </div>
              <ul className="lower-footer-national-links">
                <li>
                  <MagicLink to="/sitemap/">{t("footer-sitemap")}</MagicLink>
                </li>
                {sections.navigation?.contest_nav_switch ? (
                  <li>
                    <MagicLink to={reverseRoute(site, "rules") || ""}>{t("footer-contest")}</MagicLink>
                  </li>
                ) : null}
                <li>
                  <MagicLink to="https://www.iheart.com/privacy/">{t("footer-privacy")}</MagicLink>
                </li>
                <li>
                  <MagicLink to="https://privacy.iheart.com/">
                    {t("your_privacy_choices")}
                    {env.YOURCHOICE_ICON && !amp ? (
                      <img
                        data-src={env.YOURCHOICE_ICON}
                        alt="Your privacy Logo"
                        className="lazyload"
                        width={13}
                        height={14}
                      />
                    ) : null}
                  </MagicLink>
                </li>
                <li>
                  <MagicLink to="https://www.iheart.com/terms/">{t("footer-terms")}</MagicLink>
                </li>
                <li>
                  <MagicLink to="https://www.iheart.com/adchoices/">
                    AdChoices
                    {env.AD_ICON && !amp ? (
                      <img data-src={env.AD_ICON} alt="AdChoices Logo" className="lazyload" width={13} height={14} />
                    ) : null}
                  </MagicLink>
                </li>
                <SiteFile t={t} broadcastFacilities={integration} />
                {sections.contact?.eeo_report_asset?.id ? (
                  <li>
                    <MagicLink
                      to={`${formatImage(sections.contact?.eeo_report_asset?.id, env.IMAGE_HOST)}?passthrough=1`}
                      target="_blank"
                    >
                      {t("footer-eeo")}
                    </MagicLink>
                  </li>
                ) : null}
                {integration?.plugin?.broadcastFacilities?.length ? (
                  <>
                    <li>
                      <MagicLink to={assistanceFile}>{t("footer-assistance")}</MagicLink>
                    </li>
                    <li>
                      <MagicLink to="/fcc-applications" target="_blank">
                        {t("fcc_applications")}
                      </MagicLink>
                    </li>
                  </>
                ) : null}
              </ul>
              {ownershipTag ? (
                <CopyRight
                  providerName={site.index?.ownership?.taxo?.source?.display_name || ownershipFallback}
                  additionalCopyright={sections.general?.additional_copyright || ""}
                />
              ) : (
                <CopyRight
                  providerName={t(ownershipTranslation)}
                  additionalCopyright={sections.general?.additional_copyright || ""}
                />
              )}
            </div>
          </section>
        </footer>
      </ClickSection.Provider>
    );
  }),
);
