import loadable from "@loadable/component";
import classnames from "classnames";
import { inject, observer } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";
import "./PremierePushdownAd.style.scss";

const Ad = loadable(() => import("../../../core/page-blocks/Ad.component"));

export const PremierePushdownAd = inject("store")(
  observer(({ store }: { store?: Store }) => {
    if (!store || !store.page.currentPage) {
      return null;
    }

    const pushdown = store.page.currentPage.blocks.find(block => block.region === "ad:top-leaderboard");
    if (!pushdown || pushdown?.value?.position === "0") {
      return null;
    }

    const adClass = classnames("component-pushdown", {
      "sticky-ad-mobile":
        pushdown?.tags?.includes("display-hints/sticky-ad-mobile") && pushdown.value.position === "3332",
    });

    return (
      <div className={adClass}>
        <Ad block={pushdown} isPrimary={pushdown.id === store.primaryBlockId} />
      </div>
    );
  }),
);

export default PremierePushdownAd;
