export const CallIcon = ({ width = 21, height = 19 }) => (
  <svg
    className="svg-icon icon-call"
    focusable="false"
    tabIndex={-1}
    width={width}
    height={height}
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="primary-path"
      d="M4.34 0c.683 0 .853.114 1.213.792.037.235 1.281 2.375 1.708 3.166.27.501.277.918.068 1.188-.922 1.187-2.135.792-2.136 1.979 0 1.188.629 2.133 2.563 4.354 2.068 2.375 4.204 3.167 5.126 3.167.604 0 2.135-1.98 2.562-2.771.427-.792 1.709.396 2.99 1.188 1.282.791 2.282 1.187 2.496 1.583.317.588-.495 1.583-1.282 2.375-.647.65-2.495 1.979-3.777 1.979-1.28 0-4.698 0-9.396-4.354C1.776 10.292.495 7.125.068 4.75-.359 2.375 1.349 1.187 1.777.792 2.204.396 3.485 0 4.339 0z"
      fill="#000"
    />
  </svg>
);
