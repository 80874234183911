import "./DropDownIcon.style.scss";

export const DropDownIcon = ({ direction = "down" }) => (
  <svg
    tabIndex={-1}
    focusable="false"
    className={`svg-icon icon-dropdown direction-${direction}`}
    width="8"
    height="6"
    fill="none"
    viewBox="0 0 8 6"
  >
    <path
      fill="#3F4447"
      className="primary-path"
      d="M.71 2.41 3.3 5c.39.39 1.02.39 1.41 0L7.3 2.41C7.93 1.78 7.48.7 6.59.7H1.41C.52.7.08 1.78.71 2.41Z"
    />
  </svg>
);
