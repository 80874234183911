import { inject } from "mobx-react";

import type { SrcSet } from "@inferno/renderer-shared-core";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { ResponsiveImage } from "../../../core/components/ResponsiveImage.component";

import styles from "./HostImage.module.scss";
import type { Store } from "@inferno/renderer-shared-core";

export interface HeaderProps {
  store?: Store;
}

export const HostImage = inject("store")((props: HeaderProps) => {
  const { store } = props;
  if (!store) {
    return null;
  }
  const { site, env } = store;
  let srcset: SrcSet[] = [];

  if (!site.sections.design?.thumbnail_image?.id) {
    return null;
  }
  const width = 133;

  const hostImgUrl = formatImage(site.sections.design?.thumbnail_image?.id, env.IMAGE_HOST);
  srcset = [
    {
      url: `${hostImgUrl}?ops=gravity(%22center%22),contain(${width},${width})&quality=80`,
      descriptor: "1x",
    },
    {
      descriptor: "2x",
      url: `${hostImgUrl}?ops=gravity(%22center%22),contain(${width * 2},${width * 2})&quality=80`,
    },
  ];

  return (
    <div className={styles.hostImage}>
      <ResponsiveImage
        src={hostImgUrl}
        srcset={srcset}
        initialWidth={width}
        initialHeight={width}
        alt={`${site.sections.general?.name} - ${site.sections.general?.positioner}`}
      />
    </div>
  );
});
