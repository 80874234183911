/* eslint-disable @typescript-eslint/no-shadow */
import { slugify } from "@ihr-radioedit/inferno-core";
import { Translation } from "react-i18next";
import { inject } from "mobx-react";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useHistory } from "react-router";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import type { Store } from "@inferno/renderer-shared-core";
import { resolveMenu } from "../../../core/lib/menu";
import { reverseRoute, resizeWatcher } from "@inferno/renderer-shared-core";
import { SocialNetworkData } from "@inferno/renderer-shared-core";
import { SitesMenu } from "../../../core/chrome/Navigation.component";
import { MagicLink } from "../../../core/ui";
import { ArrowIcon } from "../../../core/components/icons/ArrowIcon.component";
import { DropDownIcon } from "../../../core/components/icons/DropDownIcon.component";
import { isWindowDefined } from "@inferno/renderer-shared-core";
import { PremiereContestsNavigationMenu } from "./PremiereContestsNavigationMenu.component";
import { PremiereLeavingSiteButton } from "./PremiereLeavingSiteButton.component";
import { useTranslation } from "react-i18next";
import styles from "./PremiereNav.module.scss";

interface PremiereNavProps {
  store?: Store;
  menus: SitesMenu[] | null;
  sections: Webapi.UserConfigFragment;
  social?: SocialNetworkData[] | null;
}

export const PremiereNavMenuItem = ({ menu, context }: { menu: SitesMenu; context?: string }) => {
  return menu.type && menu.type === "link_warning" ? (
    <PremiereLeavingSiteButton url={menu.href ?? ""} label={menu.label ?? ""} type={menu.type ?? ""} />
  ) : (
    <Translation>
      {t => (
        <MagicLink
          className={styles.menuLabel}
          to={menu.href || ""}
          label={menu.label || ""}
          target={menu.target || ""}
          context={context || menu.context}
        >
          {t(`${menu.label}`)}
        </MagicLink>
      )}
    </Translation>
  );
};

export const PremiereNav = inject("store")(({ store, sections, menus }: PremiereNavProps) => {
  if (!store || !menus) {
    return null;
  }

  const navBackgroundColor = sections.design?.navigation_color;
  const navLinksColor = sections.design?.navigation_links_color;
  const navStyles = `
        :root {
            ${navBackgroundColor ? ` --premiere-navigation-background-color: ${navBackgroundColor};` : ""}
            ${navLinksColor ? ` --premiere-navigation-links-color: ${navLinksColor};` : ""}
        }`;

  const history = useHistory();
  const navListEl = useRef<HTMLUListElement>(null);

  const brandIds = useMemo(() => {
    const brandIdss: { [key: string]: string } = {};
    store?.site.micrositeReferences.forEach(index => (brandIdss[`/index${index._id}`] = index.slug));
    return brandIdss;
  }, [store?.site.micrositeReferences]);

  const resolvedMenus = useMemo(() => {
    return menus?.map(menu => resolveMenu(menu, store?.env.MICROSITE_ROOT, null, store?.site, brandIds));
  }, [menus, store?.env.MICROSITE_ROOT, store?.site, brandIds]);
  const [menuTop, setMenuTop] = useState(0);
  const [longNavigation, setLongNavigation] = useState(false);
  const [isVisible, setVisibility] = useState("");
  const [showMore, setShowMore] = useState(store.stackedNavState);
  const { t } = useTranslation();

  const toggleDropdownMenu = (label: string) => {
    if (!isVisible || isVisible !== label) {
      setVisibility(label);
    } else {
      setVisibility("");
    }
  };

  const toggleMore = () => {
    store.storeStackedNavState(!showMore);
    setShowMore(!showMore);

    if (!!isVisible) {
      setVisibility("");
    }
  };

  const handleWindowResize = () => {
    // We have to dyanmically calculate the length of our child items.
    // If they are longer than the parent element we need to show the "MORE" button.
    const menuPosition = navListEl?.current?.getBoundingClientRect();

    if (!!menuPosition) {
      setMenuTop(Math.floor(menuPosition.top));
    }

    let menuItemsLength = 0;
    const menuWidth = navListEl?.current?.offsetWidth;

    if (!!menuWidth) {
      menuItemsLength = Object.values(navListEl?.current!.childNodes).reduce(
        (total: number, i: any) => total + i.offsetWidth,
        -2,
      );
    }

    if (menuWidth! < menuItemsLength) {
      setLongNavigation(true);
    } else {
      setLongNavigation(false);
    }
  };

  const resetMenus = useCallback(() => {
    store.storeStackedNavState(false);
    setShowMore(false);
    setVisibility("");
  }, [store]);

  useEffect(() => {
    if (isWindowDefined()) {
      handleWindowResize();
      resizeWatcher.onWidthChange.subscribe(handleWindowResize);
      return () => resizeWatcher.onWidthChange.unsubscribe(handleWindowResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    history.listen(resetMenus);
  }, [history, resetMenus]);

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: navStyles }} />
      {!!resolvedMenus.length ? (
        <nav data-cy="pn-nav" className={`${styles.horizontalNav} ${showMore ? "stacked" : ""}`}>
          <div className={`${styles.outerWrap} ${isVisible ? "visible" : ""}`}>
            <ul
              data-cy="menuLabel"
              className={styles.mainNav}
              ref={navListEl}
              style={{
                maxHeight: `calc(100vh - ${menuTop}px)`,
              }}
            >
              {resolvedMenus.map((menuItem: SitesMenu, index: number) => (
                <li className={styles.topLabel} key={`${menuItem.label}_${menuItem.id}`}>
                  {!menuItem.children?.length ? (
                    <PremiereNavMenuItem
                      menu={menuItem}
                      context={menuItem.label ? `${slugify(menuItem.label)}_${index}` : undefined}
                    />
                  ) : (
                    <>
                      <Translation>
                        {t => (
                          <button
                            data-cy="menuitem"
                            className={styles.menuLabel}
                            onClick={() => toggleDropdownMenu(menuItem.label!)}
                          >
                            {t(`${menuItem.label}`)}
                            <span className={styles.icon}>
                              {isVisible === menuItem.label ? (
                                <DropDownIcon direction="up" />
                              ) : (
                                <DropDownIcon direction="down" />
                              )}
                            </span>
                          </button>
                        )}
                      </Translation>
                      <div className={styles.dropdownOverlay}>
                        <ul
                          data-cy="list-submenu"
                          className={`${styles.submenu} ${isVisible === menuItem.label ? "visible" : ""}`}
                          aria-hidden={!!isVisible}
                          style={{
                            maxHeight: `calc(100vh - ${menuTop + 100}px)`,
                          }}
                        >
                          {menuItem.children?.map(childMenuItem => (
                            <li key={`${childMenuItem.label}_${childMenuItem.id}`}>
                              <PremiereNavMenuItem
                                menu={childMenuItem}
                                context={childMenuItem.label ? `${slugify(childMenuItem.label)}_${index}` : undefined}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </li>
              ))}
              {store?.site.sections.navigation?.contest_nav_switch ? (
                <Translation>
                  {t => (
                    <PremiereContestsNavigationMenu
                      store={store}
                      forceOpen={isVisible === t("promotions")}
                      top={menuTop}
                      callback={toggleDropdownMenu}
                    />
                  )}
                </Translation>
              ) : null}
              {sections.navigation?.contact_menu_switch ? (
                <li className={styles.topLabel}>
                  <Translation>
                    {t => (
                      <MagicLink
                        className={styles.menuLabel}
                        to={reverseRoute(store.site, "contact") || ""}
                        context="contact"
                      >
                        {t("contact")}
                      </MagicLink>
                    )}
                  </Translation>
                </li>
              ) : null}
              {sections.partners?.postup_switch ? (
                <li className={styles.topLabel}>
                  <Translation>
                    {t => (
                      <MagicLink
                        className={styles.menuLabel}
                        to={reverseRoute(store.site, "newsletter") || ""}
                        context="newsletter"
                      >
                        {t("newsletter")}
                      </MagicLink>
                    )}
                  </Translation>
                </li>
              ) : null}
              {sections.navigation?.advertise_switch ? (
                <li className={styles.topLabel}>
                  <Translation>
                    {t => (
                      <MagicLink
                        className={styles.menuLabel}
                        to={reverseRoute(store.site, "advertise") || ""}
                        context="advertise"
                      >
                        {t("advertise_with_us", {
                          name: store.site.sections.general?.name,
                        })}
                      </MagicLink>
                    )}
                  </Translation>
                </li>
              ) : null}
              {longNavigation ? (
                <li aria-hidden="true">
                  <span
                    style={{
                      display: "inline-block",
                      width: "100px",
                      border: "solid 1px transparent",
                    }}
                  >
                    <p style={{ width: 0, height: 0, fontSize: 0, color: "transparent" }} aria-hidden="true">
                      This is a spacer to push nav content over when part of the nav is hidden behind the "MORE" button
                    </p>
                  </span>
                </li>
              ) : null}
            </ul>

            {longNavigation ? (
              <button
                data-cy="more-trigger"
                className={`${styles.menuLabel} ${styles.moreTrigger}`}
                onClick={toggleMore}
              >
                {showMore ? (
                  <span className={styles.icon}>
                    {t("less")} <ArrowIcon direction="up" />
                  </span>
                ) : (
                  <span className={styles.icon}>
                    {t("more")} <ArrowIcon direction="down" />
                  </span>
                )}
              </button>
            ) : null}
          </div>
        </nav>
      ) : null}
    </>
  );
});
