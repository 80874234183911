import { inject, observer } from "mobx-react";
import { useEffect, useState, useCallback } from "react";
import type { LeadsCardFragment } from "@ihr-radioedit/inferno-webapi";

import { isPage } from "../../../core/lib/utilities";
import { isWindowDefined, resizeWatcher } from "@inferno/renderer-shared-core";
import { Button, ButtonKind, FauxButton } from "../../../core/ui";
import { ClickSection } from "@inferno/renderer-shared-core";
import { socialConfigAbstract } from "@inferno/renderer-shared-core";
import { useScrollDirection } from "../../../core/lib/scrollDirection.hook";
import { SocialIcons } from "@inferno/renderer-shared-ui";
import { CallIcon } from "../../../core/components/icons/CallIcon.component";
import { HeaderSearch } from "../../../core/chrome/HeaderSearch.component";
import { HeaderUser } from "../../../core/chrome/HeaderUser.component";
import { PremiereLogo } from "./PremiereLogo.component";
import { PremiereNav } from "./PremiereNav.component";
import { HostImage } from "./HostImage.component";
import { COMPONENT_BREAKPOINTS } from "../../../core/ui/constants";
import { useTranslation } from "react-i18next";

import "./Header.style.scss";
import type { Store } from "@inferno/renderer-shared-core";

export interface HeaderProps {
  store?: Store;
  content?: LeadsCardFragment;
}

export const Header = inject("store")(
  observer((props: HeaderProps) => {
    const { store, content } = props;
    if (!store) {
      return null;
    }
    const { site } = store;
    const { sections } = site;
    const [showSlimHeader, setShowSlimHeader] = useState(false);
    const [isMediumScreen, setIsMediumScreen] = useState(false);
    const { t } = useTranslation();

    const handleWindowResize = () => {
      if (window.innerWidth > COMPONENT_BREAKPOINTS.mediumScreenBreakpoint) {
        setIsMediumScreen(true);
      } else {
        setIsMediumScreen(false);
      }
    };

    useEffect(() => {
      if (isWindowDefined()) {
        handleWindowResize();
        resizeWatcher.onWidthChange.subscribe(handleWindowResize);
        return () => resizeWatcher.onWidthChange.unsubscribe(handleWindowResize);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scrollDirection = useScrollDirection(150, ".component-routes");

    const scrollWatcher = useCallback(() => {
      const isSlimHeaderVisible = scrollDirection === "down";
      setShowSlimHeader(isSlimHeaderVisible);
    }, [scrollDirection]);

    useEffect(() => {
      if (isWindowDefined()) {
        scrollWatcher();
      }
    }, [scrollWatcher]);

    const network = socialConfigAbstract(sections.social);
    const studioLine = sections.contact?.request_phone_number;
    const linkName = content?.link?.name || isMediumScreen ? t("find_your_station") : t("find_station");
    const headerBackgroundColor = sections.design?.header_color;
    const navBackgroundColor = sections.design?.navigation_color;
    const css = `
            :root {
                ${headerBackgroundColor ? ` --premiere-header-background-color: ${headerBackgroundColor};` : ""}
                ${navBackgroundColor ? ` --premiere-navigation-background-color: ${navBackgroundColor};` : ""}
            }
        `;

    // Currently, microsites also return "home" as the name of their homepage.
    // Adding this check to only return homepage items on the PRIMARY homepage, not microsite homepages.
    const isPrimaryHomePage = isPage(store, "home") && store?.page.currentPage?.layoutId !== "microhome";

    return (
      <header className="component-premiere-site-header">
        <style dangerouslySetInnerHTML={{ __html: css }} />
        <section className="wrapper--header">
          <ClickSection.Provider value="header">
            <section className="search-user-container">
              <HeaderUser location="header" showMenu={false} />
              {sections.navigation?.search_switch ? <HeaderSearch location="premiere-header" /> : null}
            </section>
            <section className={`branding ${isPrimaryHomePage && !showSlimHeader ? "homepage" : "header--slim"}`}>
              {isPrimaryHomePage && !showSlimHeader ? (
                <>
                  {site.sections.design?.thumbnail_image?.id && <HostImage />}
                  <PremiereLogo location={!showSlimHeader ? "header-block" : "header"} />
                </>
              ) : (
                <PremiereLogo location="header" />
              )}
            </section>
            <section className="find-your-station">
              {sections.navigation?.find_your_station_switch ? (
                <FauxButton
                  kind={ButtonKind.PRIMARY}
                  size="small"
                  target={content?.link?.target || "_self"}
                  to={content?.link?.urls?.web || content?.link?.urls?.device || "/howtolisten/"}
                  className="listen-button"
                >
                  {linkName}
                </FauxButton>
              ) : null}
            </section>
          </ClickSection.Provider>
        </section>
        {!!isPrimaryHomePage && !showSlimHeader ? (
          <section className="wrapper--social">
            <Button kind={ButtonKind.PRIMARY} size="small" className="social-icons-button" label="socialIcons">
              <SocialIcons networks={network} />
            </Button>
            {studioLine ? (
              <Button kind={ButtonKind.PRIMARY} size="small" className="call-button" label="callIcon">
                <a href={`tel:${studioLine}`}>
                  <CallIcon />
                  <span className="text">{t("call_in")}</span>
                </a>
              </Button>
            ) : null}
          </section>
        ) : null}
        <section className="wrapper--header-nav">
          <ClickSection.Provider value="nav">
            <PremiereNav sections={sections} menus={sections.navigation!.menu!} social={null} />
          </ClickSection.Provider>
        </section>
      </header>
    );
  }),
);
